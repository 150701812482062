<template>
  <div>
    <el-aside>
      <SideMenu :items="items"></SideMenu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
import SideMenu from "@/components/common/SideMenu";

export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      items: [
        { icon: "el-icon-rank", index: "test8-1", title: "科创活动" },
         ],
    };
  },
};
</script>

<style>
</style>
